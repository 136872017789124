import { useEffect, useState } from "react";
import Body from "../../comps/body/Body";
import ApiManager from "../../comps/Api";
import { useParams } from "react-router-dom";
import { formatInputDate } from "../../comps/StaticClasses";
import Loading from "../../comps/loading/Loading";

function CheckIn() {

    const { ID } = useParams();
    const apiManager = new ApiManager();

    const[reservation, setReservation] = useState(null);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const response = await apiManager.callApi("/getBy/" + ID, "GET");
    
                setReservation(response);
            } catch (error) {
                console.error("Api ERROR: " + error.message);
            }
        }

        fetchDataFromApi();
    }, []);

    if(reservation === null) {
        return(
            <Body title={"Check In"}>
                <Loading />
            </Body>
        )
    }

    return (
        <Body title="Check In">
            <section>
                <div className="info">
                    <div className="title">
                        <h3>Reservation Info</h3>
                    </div>

                    <form className="show-info">

                        <div className="row">
                            <label>
                                <span>From</span>
                                <input type="date" defaultValue={formatInputDate(reservation.From)} min={formatInputDate(new Date())} required />
                            </label>

                            <label>
                                <span>To</span>
                                <input type="date" defaultValue={formatInputDate(reservation.To)} min={formatInputDate(new Date())} required />
                            </label>

                            <label>
                                <span>Status</span>
                                <select id="status" readOnly required>
                                    <option value={reservation.Status}>{reservation.Status ? "Confirmed" : "Unconfirmed"}</option>
                                    <option value={!reservation.Status}>{!reservation.Status ? "Confirmed" : "Unconfirmed"}</option>
                                </select>
                            </label>

                            <label>
                                <span>Room</span>
                                <input type="number" defaultValue={reservation.IdRoom} min={formatInputDate(new Date())} required />
                            </label>
                        </div>

                        <div className="action">
                            <button className="btn blue">Update</button>
                        </div>

                    </form>
                </div>

                <div className="info">
                    <div className="title">
                        <h3>Made By</h3>
                    </div>

                    <form className="show-info">

                        <div className="row">
                            <label>
                                <span>Full Name</span>
                                <input type="text" defaultValue={reservation.MadeBy.FirstName + " " + reservation.MadeBy.LastName} readOnly required />
                            </label>

                            <label>
                                <span>Birthday</span>
                                <input type="date" defaultValue={formatInputDate(reservation.MadeBy.Birthday)} min={formatInputDate(new Date())} required />
                            </label>
                        </div>

                        <div className="row">
                            <label>
                                <span>Passport / Identity card</span>
                                <input type="text" placeholder="123XXXKO...L" required />
                            </label>

                            <label>
                                <span>Type</span>
                                <select required>
                                    <option selected disabled>Type...</option>
                                    <option>Passport</option>
                                    <option>Identity card</option>
                                </select>
                            </label>

                            <label>
                                <span>Visa</span>
                                <input type="text" placeholder="123XXXKO...L" />
                            </label>
                        </div>
                    </form>
                </div>

                {
                    <div className="info">
                        <div className="title">
                            <h3>1. Jitka Kramperova</h3>
                        </div>

                        <form className="show-info">

                            <div className="row">
                                <label>
                                    <span>Full Name</span>
                                    <input type="text" defaultValue={reservation.MadeBy.FirstName + " " + reservation.MadeBy.LastName} readOnly required />
                                </label>

                                <label>
                                    <span>Birthday</span>
                                    <input type="date" defaultValue={formatInputDate(reservation.MadeBy.Birthday)} min={formatInputDate(new Date())} required />
                                </label>
                            </div>

                            <div className="row">
                                <label>
                                    <span>Passport / Identity card</span>
                                    <input type="text" placeholder="123XXXKO...L" required />
                                </label>

                                <label>
                                    <span>Type</span>
                                    <select required>
                                        <option selected disabled>Type...</option>
                                        <option>Passport</option>
                                        <option>Identity card</option>
                                    </select>
                                </label>

                                <label>
                                    <span>Visa</span>
                                    <input type="text" placeholder="123XXXKO...L" />
                                </label>
                            </div>

                            <div className="action">
                                <button className="btn blue" type="submit">Done</button>
                            </div>

                        </form>
                    </div>
                }

            </section>
        </Body>
    )
}

export default CheckIn;