import Aside from "../aside/Aside";
import Header from "../header/Header";

function Body({ children, title }) {
    return(
        <main className="body">
            <Aside />

            <Header title={title} />

            {children}

        </main>
    )
}

export default Body;