class AuthManager {

    isLoggedIn() {
        return localStorage.getItem("USER") === null ? false : true;
    }

    LogIn(email) {
        localStorage.setItem("USER", email);
    };

    LogOut() {
        localStorage.removeItem("USER");
    }
}

export default AuthManager;