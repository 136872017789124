import { useEffect, useState } from "react";
import ApiManager from "../../comps/Api";
import Body from "../../comps/body/Body";
import Loading from "../../comps/loading/Loading";

function Services() {

    const[services, setServices] = useState(null);

    const apiManager = new ApiManager();

    useEffect(() => {

        const fetchDataFromApi = async () => {
            try {
                // const response = await apiManager.callApi("/getRooms", "GET");
                
                setServices(true);
            } catch (error) {
                console.error("Api ERROR: " + error.message);
            }
        };
        
        fetchDataFromApi();
    }, []);

    if(services === null) {
        return (
            <Body title="Services">
                <Loading />
            </Body>
        )
    }

    return(
        <Body title="Services">
            <section>

                <div className="btn-body">
                    <button className="btn blue">Services</button>
                </div>

                services
            </section>
        </Body>
    )
}

export default Services;