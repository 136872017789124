import { useEffect, useState } from "react";
import ApiManager from "../../comps/Api";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import AuthManager from "../../comps/Auth";

function Login() {

    const[email, setEmail] = useState(null);
    const[password, setPassword] = useState(null);

    const navigate = useNavigate();

    const apiManager = new ApiManager();
    const auth = new AuthManager();

    useEffect(() => {
        if(auth.isLoggedIn()) {
            navigate("/home")
        }
    }, [])

    const onSubmit = (event) => {
        event.preventDefault();

        const fetchDataFromApi = async () => {
            try {
                const user = {
                    Email: email,
                    Password: password,
                }

                const response = await apiManager.callApi("/verify", "POST", user);

                if(response) {
                    auth.LogIn(email);
                    navigate("/home");
                }
            } catch (error) {
                console.error("Api ERROR: " + error.message);
            }
        };
        
        fetchDataFromApi();
    }

    return(
        <main className="login">

            <form onSubmit={onSubmit}>

                <h2>Log In</h2>

                <label>
                    <span>Email</span>
                    <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Enter email..." required />
                </label>

                <label>
                    <span>Password</span>
                    <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Enter password..." required />
                </label>

                <button className="btn" type="submit">Log In</button>
            </form>

        </main>
    )
}

export default Login;