import { useParams } from "react-router-dom";
import Body from "../../../comps/body/Body";
import Loading from "../../../comps/loading/Loading";
import ApiManager from "../../../comps/Api";
import { useEffect, useState } from "react";
import { formatInputDate } from "../../../comps/StaticClasses";

function Reserv() {

    const[reservation, setReservation] = useState(null);
    const { ID } = useParams();

    const apiManager = new ApiManager();

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const response = await apiManager.callApi("/getBy/" + ID, "GET");

                setReservation(response);
            } catch (error) {
                console.error("Api ERROR: " + error.message);
            }
        };
        
        fetchDataFromApi();
    }, []);

    if(reservation === null) {
        return(
            <Body title="Reservation">
                <Loading />
            </Body>
        )
    }

    return(
        <Body title="Reservation">
            <section>
                
                <div className="info">
                    <div className="title">
                        <h3>Basic Information</h3>
                    </div>

                    <form className="show-info">

                        <div className="row">
                            <label>
                                <span>Id</span>
                                <input type="text" value={reservation.Id} placeholder="Id..." readOnly />
                            </label>

                            <label>
                                <span>From</span>
                                <input type="date" value={formatInputDate(reservation.From)} readOnly required />
                            </label>

                            <label>
                                <span>To</span>
                                <input type="date" value={formatInputDate(reservation.To)} readOnly required />
                            </label>
                        </div>  

                        <div className="row">
                            <label>
                                <span>Room</span>
                                <input type="number" value={reservation.IdRoom} placeholder="Id of Room..." min={1} readOnly required />
                            </label>

                            <label>
                                <span>Status</span>
                                <select id="status" readOnly required>
                                    <option value={reservation.Status}>{reservation.Status ? "Confirmed" : "Unconfirmed"}</option>
                                    <option value={!reservation.Status}>{!reservation.Status ? "Confirmed" : "Unconfirmed"}</option>
                                </select>
                            </label>

                            <label>
                                <span>Create At</span>
                                <input type="date" value={formatInputDate(reservation.CreateAt)} placeholder="Tel..." readOnly required />
                            </label>
                        </div>

                        <div className="action">
                            <button className="btn red">Delete</button>
                            <button className="btn blue">Save</button>
                        </div>

                    </form>

                </div>

                <div className="info">
                    <div className="title">
                        <h3>Reservation Made By</h3>
                    </div>

                    <form className="show-info">

                        <div className="row">
                            <label>
                                <span>Id</span>
                                <input type="text" value={reservation.MadeBy.Id} placeholder="Id..." readOnly />
                            </label>

                            <label>
                                <span>Name</span>
                                <input type="text" value={reservation.MadeBy.FirstName} placeholder="Name..." readOnly required />
                            </label>

                            <label>
                                <span>Surname</span>
                                <input type="text" value={reservation.MadeBy.LastName} placeholder="Surname..." readOnly required />
                            </label>
                        </div>

                        <div className="row">
                            <label>
                                <span>Birthday</span>
                                <input type="date" value={formatInputDate(reservation.MadeBy.Birthday)} readOnly required />
                            </label>

                            <label>
                                <span>Tel</span>
                                <input type="tel" value={reservation.MadeBy.Tel} placeholder="Tel..." readOnly required />
                            </label>

                            <label>
                                <span>Email</span>
                                <input type="email" value={reservation.MadeBy.Email} placeholder="Email..." readOnly required />
                            </label>
                        </div>

                        <div className="action">
                            <button className="btn blue">Save</button>
                        </div>

                    </form>

                </div>

                {/* {reservation.Residents.map((resident, index) => (
                    <div className="info">
                        <div className="title">
                            <h3>{++index + ". Resident: " + resident.FirstName + " " + resident.LastName}</h3>
                        </div>

                        <form className="show-info">

                            <div className="row">
                                <label>
                                    <span>Id</span>
                                    <input type="text" value={resident.Id} placeholder="Id..." readOnly />
                                </label>

                                <label>
                                    <span>Name</span>
                                    <input type="text" value={resident.FirstName} placeholder="Name..." required />
                                </label>

                                <label>
                                    <span>Surname</span>
                                    <input type="text" value={resident.LastName} placeholder="Surname..." required />
                                </label>
                            </div>

                            <div className="row">
                                <label>
                                    <span>Birthday</span>
                                    <input type="date" value={formatInputDate(resident.Birthday)} required />
                                </label>

                                <label>
                                    <span>Tel</span>
                                    <input type="tel" value={resident.Tel} placeholder="Tel..." required />
                                </label>

                                <label>
                                    <span>Email</span>
                                    <input type="email" value={resident.Email} placeholder="Email..." required />
                                </label>
                            </div>

                            <div className="action">
                                <button className="btn blue">Save</button>
                            </div>

                        </form>

                    </div>
                ))} */}

            </section>
        </Body>
    )
}

export default Reserv;