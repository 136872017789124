import { useEffect, useState } from "react";
import Body from "../../comps/body/Body";
import Loading from "../../comps/loading/Loading";
import ApiManager from "../../comps/Api";
import { Link, useNavigate } from "react-router-dom";

function Residents() {

    const[residents, setResidents] = useState(null);
    const[searchQuery, setSearchQuery] = useState(null);
    const navigate = useNavigate();

    const itemsPerPage = 4;
    const [currentPage, setCurrentPage] = useState(0);

    const[search, setSearch] = useState("");

    const apiManager = new ApiManager();

    useEffect(() => {

        const fetchDataFromApi = async () => {
            try {
                const response = await apiManager.callApi("/getPersons", "GET");

                const sorted = response.sort((a, b) => {
                    if (a.FirstName < b.FirstName) return -1;
                    if (a.FirstName > b.FirstName) return 1;
                    return 0;
                })

                setResidents(sorted);

                // const finalArray = response.slice(pagination, pagination + 5);

                setSearchQuery(sorted.slice(0, itemsPerPage));

            } catch (error) {
                console.error("Api ERROR: " + error.message);
            }
        };

        fetchDataFromApi();
    }, []);

    const Search = (event) => {
        event.preventDefault();

        const searchfill = search.toLowerCase();

        const filteredResident = residents.filter((resident) =>
            resident.FirstName.toLowerCase().includes(searchfill) ||
            resident.LastName.toLowerCase().includes(searchfill) ||
            resident.Tel.toLowerCase().includes(searchfill) ||
            resident.Email.toLowerCase().includes(searchfill)
        );
  
        setSearchQuery(filteredResident);
    };

    const handleNextPage = () => {
        if((currentPage + 1) * itemsPerPage > residents.length) {
            return
        }

        setCurrentPage(currentPage + 1);
        setSearchQuery(residents.slice(currentPage + 1 * itemsPerPage, currentPage + 1 * itemsPerPage + itemsPerPage))
    } 

    const handlePreviousPage = () => {
        if(currentPage <= 0) {
            return
        }

        setCurrentPage(currentPage - 1)
        setSearchQuery(residents.slice((currentPage - 1) * itemsPerPage, (currentPage - 1) * itemsPerPage + itemsPerPage))
    } 

    if(residents === null) {
        return(
            <Body title="Residents">
                <Loading />
            </Body>
        )
    }

    return(
        <Body title="Residents">

            <section>

                <form className="search" onSubmit={Search}>
                    <label>
                        <span>Search</span>
                        <input type="text" onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                    </label>

                    <div className="action">
                        <button type="button" className="normal" onClick={() => setSearchQuery(residents)}>
                            <svg clipRule="evenodd" width="24" height="24" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>
                        </button>

                        <button type="submit" className="normal">
                            <svg clipRule="evenodd" width="24" height="24" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15.97 17.031c-1.479 1.238-3.384 1.985-5.461 1.985-4.697 0-8.509-3.812-8.509-8.508s3.812-8.508 8.509-8.508c4.695 0 8.508 3.812 8.508 8.508 0 2.078-.747 3.984-1.985 5.461l4.749 4.75c.146.146.219.338.219.531 0 .587-.537.75-.75.75-.192 0-.384-.073-.531-.22zm-5.461-13.53c-3.868 0-7.007 3.14-7.007 7.007s3.139 7.007 7.007 7.007c3.866 0 7.007-3.14 7.007-7.007s-3.141-7.007-7.007-7.007z" fillRule="nonzero"/></svg>
                        </button>
                    </div>
                </form>
                
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Tel</th>
                            <th>Email</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody> 

                        {searchQuery.map((resi, index) => (
                            <tr key={index}>
                                <td>{resi.Id}</td>

                                <td>{`${resi.FirstName} ${resi.LastName}`}</td>

                                <td>{resi.Tel}</td>

                                <td>{resi.Email}</td>

                                <td>
                                    <Link className="btn" to={"/resident/" + resi.Id}>SHOW</Link>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>

                <div className="pagination">
                    <button className="normal" onClick={() => handlePreviousPage()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fillRule="evenodd" clipRule="evenodd"><path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"/></svg>
                    </button>

                    <h3>Page: {currentPage + 1}</h3>

                    <button className="normal" onClick={() => handleNextPage()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fillRule="evenodd" clipRule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
                    </button>
                </div>

            </section>

        </Body>
    )
}

export default Residents;