import { useEffect, useState } from "react";
import Body from "../../../comps/body/Body";
import { useParams } from "react-router-dom";
import Loading from "../../../comps/loading/Loading";
import ApiManager from "../../../comps/Api";
import { formatDate, formatInputDate } from "../../../comps/StaticClasses";
import "./Resident.css";

function Resident() {

    const[title, setTitle] = useState("Resident");
    const[resident, setResident] = useState(null);
    const { ID } = useParams();

    const apiManager = new ApiManager();

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const response = await apiManager.callApi("/getPersonBy/" + ID, "GET");

                setResident(response);
                setTitle(resident.FirstName + " " + resident.LastName)
            } catch (error) {
                console.error("Api ERROR: " + error.message);
            }
        };
        
        fetchDataFromApi();
    }, []);

    if(resident === null) {
        return (
            <Body title={title}>
                <Loading />
            </Body>
        )
    }

    return(
        <Body title={title}>

            <section>

                <div className="info">
                    <div className="title">
                        <h3>Basic Information</h3>
                    </div>

                    <form className="show-info">

                        <div className="row">
                            <label>
                                <span>Id</span>
                                <input type="text" value={resident.Id} placeholder="Id..." readOnly />
                            </label>

                            <label>
                                <span>Name</span>
                                <input type="text" value={resident.FirstName} placeholder="Name..." readOnly required />
                            </label>

                            <label>
                                <span>Surname</span>
                                <input type="text" value={resident.LastName} placeholder="Surname..." readOnly required />
                            </label>
                        </div>

                        <div className="row">
                            <label>
                                <span>Birthday</span>
                                <input type="date" value={formatInputDate(resident.Birthday)} readOnly required />
                            </label>

                            <label>
                                <span>Tel</span>
                                <input type="tel" value={resident.Tel} placeholder="Tel..." readOnly required />
                            </label>

                            <label>
                                <span>Email</span>
                                <input type="email" value={resident.Email} placeholder="Email..." readOnly required />
                            </label>
                        </div>

                        <div className="action">
                            <button className="btn red">Delete</button>
                            <button className="btn blue">Save</button>
                        </div>

                    </form>
                </div>

                <div className="info">
                    <div className="title">
                        <h3>Address</h3>
                    </div>

                    <form className="show-info">

                        <div className="row">
                            <label>
                                <span>Id</span>
                                <input type="text" value={resident.PersonAddress.Id} placeholder="Id..." readOnly />
                            </label>

                            <label>
                                <span>First Line</span>
                                <input type="text" value={resident.PersonAddress.FirstLine} placeholder="Address1..." readOnly required />
                            </label>

                            <label>
                                <span>Second Line</span>
                                <input type="text" value={resident.PersonAddress.SecondLine === null ? "" : resident.PersonAddress.SecondLine} placeholder="Address2..." readOnly />
                            </label>
                        </div>

                        <div className="row">
                            <label>
                                <span>City</span>
                                <input type="text" value={resident.PersonAddress.City} placeholder="City..." readOnly required />
                            </label>

                            <label>
                                <span>Postal code</span>
                                <input type="tel" value={resident.PersonAddress.Psc} placeholder="Psc..." readOnly required />
                            </label>

                            <label>
                                <span>Country</span>
                                <input type="email" value={resident.PersonAddress.Country} placeholder="Country..." readOnly required />
                            </label>
                        </div>

                        <div className="action">
                            <button className="btn blue">Save</button>
                        </div>

                    </form>
                </div>

            </section>

        </Body>
    )
}

export default Resident;