import { useNavigate } from "react-router-dom";
import AuthManager from "./Auth"
import Loading from "./loading/Loading";
import { useEffect } from "react";

function RouterProtector({ children }) {
    
    const auth = new AuthManager();
    const navigate = useNavigate();

    useEffect(() => {
        if(!auth.isLoggedIn()) {
            navigate("/");
        }
    }, []);
    
    if(!auth.isLoggedIn()) {
        return(
            <Loading setFullSize={true} />
        )
    } else {
        return(
            <>
                { children }
            </>
        )
    }
} 

export default RouterProtector;