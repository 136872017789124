import { useEffect, useState } from "react";
import Body from "../../comps/body/Body";
import ApiManager from "../../comps/Api";
import Loading from "../../comps/loading/Loading";
import "./Reservations.css";
import { Link } from "react-router-dom";
import { formatDate } from "../../comps/StaticClasses";

function Reservations() {

    const[reservations, setReservations] = useState(null);
    const[searchQuery, setSearchQuery] = useState(null); 

    const[searchDate, setDate] = useState("");
    const[searchName, setName] = useState("");

    const apiManager = new ApiManager();

    useEffect(() => {

        const fetchDataFromApi = async () => {
            try {
                const response = await apiManager.callApi("/getAll", "GET");
                
                setReservations(response);
                setSearchQuery(response)
            } catch (error) {
                console.error("Api ERROR: " + error.message);
            }
        };
        
        fetchDataFromApi();
    }, []);

    if(reservations === null) {
        return(
            <Body title="Reservations">
                <Loading />
            </Body>
        )
    }

    const Search = (event) => {
        event.preventDefault();

        const filterName = searchName.toLowerCase();

        // console.log(new Date(reservations[0].From));
        // console.log(new Date(searchDate));
        // console.log(new Date(reservations[2].From) > new Date(searchDate));

        if(searchName === null) {
            const filteredReserv = reservations.filter((reserv) =>
                new Date(reserv.From) >= new Date(searchDate)
            );

            setSearchQuery(filteredReserv);
        } else {
            const filteredReserv = reservations.filter((reserv) =>
                (reserv.MadeBy.FirstName.toLowerCase().includes(filterName) || reserv.MadeBy.LastName.toLowerCase().includes(filterName)) 
                && new Date(reserv.From) >= new Date(searchDate)
            );

            setSearchQuery(filteredReserv);
        }

        // const filteredReserv = reservations.filter((reserv) =>
        //     (reserv.MadeBy.FirstName.toLowerCase().includes(filterName) ||
        //     reserv.MadeBy.LastName.toLowerCase().includes(filterName)) ||
        //     new Date(reserv.From) >= new Date(searchDate)
        // );
  
    };

    return(
        <Body title="Reservations">

            <section className="reservations">

                <div className="btn-body">
                    <Link className="btn blue" to="/new-reservation">Walk In</Link>
                </div>

                <form className="search" onSubmit={Search}>
                    <label>
                        <span>Name</span>
                        <input type="text" onChange={(e) => setName(e.target.value)} placeholder="Name..." />
                    </label>

                    <label>
                        <span>From</span>
                        <input type="date" onChange={(e) => setDate(e.target.value)}/>
                    </label>

                    <div className="action">
                        <button type="button" className="normal" onClick={() => setSearchQuery(reservations)}>
                            <svg clipRule="evenodd" width="24" height="24" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>
                        </button>

                        <button type="submit" className="normal">
                            <svg clipRule="evenodd" width="24" height="24" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15.97 17.031c-1.479 1.238-3.384 1.985-5.461 1.985-4.697 0-8.509-3.812-8.509-8.508s3.812-8.508 8.509-8.508c4.695 0 8.508 3.812 8.508 8.508 0 2.078-.747 3.984-1.985 5.461l4.749 4.75c.146.146.219.338.219.531 0 .587-.537.75-.75.75-.192 0-.384-.073-.531-.22zm-5.461-13.53c-3.868 0-7.007 3.14-7.007 7.007s3.139 7.007 7.007 7.007c3.866 0 7.007-3.14 7.007-7.007s-3.141-7.007-7.007-7.007z" fillRule="nonzero"/></svg>
                        </button>
                    </div>
                </form>

                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody> 

                        {searchQuery.map((reser, index) => (
                            <tr key={index}>
                                <td>{reser.Id}</td>

                                <td>{`${reser.MadeBy.FirstName} ${reser.MadeBy.LastName}`}</td>

                                <td>{formatDate(reser.From)}</td>
                                
                                <td>{formatDate(reser.To)}</td>

                                <td className={reser.Status ? "green" : "red"}>{reser.Status ? "Confirmed" : "Unconfirmed"}</td>

                                <td className="btn-body">
                                    <div>
                                        <Link className="btn" to={"/reservation/" + reser.Id}>SHOW</Link>
                                        <Link className="btn blue" to={"/check-in/" + reser.Id}>Check In</Link>
                                    </div>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>

            </section>

        </Body>
    )
}

export default Reservations;