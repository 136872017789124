import "./Loading.css";

function Loading({ setFullSize }) {
    return(
        <section className={setFullSize ? "full" : "loading"}>
            <span className="loader"></span>
        </section>
    )
}

export default Loading;