import { useEffect, useState } from "react";
import Body from "../../comps/body/Body";
import "./Home.css";
import { formatDate } from "../../comps/StaticClasses";
import Loading from "../../comps/loading/Loading";
import ApiManager from "../../comps/Api";

function Home() {

    const [currentTime, setCurrentTime] = useState(new Date());
    const [data, setData] = useState(null);

    const apiManager = new ApiManager();

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const response = await apiManager.callApi("/homeData", "GET");
                setData(response);
            } catch (error) {
                console.error("Api ERROR: " + error.message);
            }
        };
        
        fetchDataFromApi();

        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
    
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    if(data === null) {
        return(
            <Body title={"Home"}>
                <Loading />
            </Body>
        )
    }
    
    return(
        <Body title={"Home"}>
            <section className="home">
                <div className="div1 tab">
                    {data}
                </div>
                
                <div className="div2 tab">
                    {formatDate(new Date())} {currentTime.toLocaleTimeString()}
                </div>

                <div className="div3 tab">
                    ...
                </div>

                <div className="div4 tab">
                    ...
                </div>
            </section>
        </Body>
    )
}

export default Home;