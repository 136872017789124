import { useNavigate } from "react-router-dom";
import ApiManager from "../../../comps/Api";
import { formatInputDate } from "../../../comps/StaticClasses";
import Body from "../../../comps/body/Body";
import { useState } from "react";

function NewReservation() {

    const apiManager = new ApiManager();
    const navigate = useNavigate();

    const[from, setFrom] = useState(null);
    const[to, setTo] = useState(null);
    const[room, setRoom] = useState(null);

    const[name, setName] = useState(null);
    const[surname, setSurname] = useState(null);
    const[birthday, setBirthday] = useState(null);
    const[email, setEmail] = useState(null);
    const[tel, setTel] = useState(null);

    const[firstLine, setFirstLine] = useState(null);
    const[secondLine, setSecondLine] = useState(null);
    const[psc, setPsc] = useState(null);
    const[city, setCity] = useState(null);
    const[country, setCountry] = useState(null);



    const createNewReservation = async (event) => {

        event.preventDefault();

        const reservation = {
            "from": from,
            "to": to,
            "madeBy": {
              "firstName": name,
              "lastName": surname,
              "birthday": birthday,
              "email": email,
              "tel": tel,
            },
            "idRoom": room,
            "address": {
              "firstLine": firstLine,
              "secondLine": secondLine,
              "psc": psc,
              "city": city,
              "country": country
            }
        }

        try {
            const response = await apiManager.callApi("/new", "POST", reservation);

            navigate("/reservations");
        } catch (error) {
            console.error("Api ERROR: " + error.message);
        }
    }

    return(
        <Body title={"New Reservation"}>

            <section>
                <div className="info">
                    <div className="title">
                        <h3>Reservation</h3>
                    </div>

                    <form className="show-info">

                        <div className="row">
                            <label>
                                <span>From</span>
                                <input type="date" onChange={(e) => setFrom(e.target.value)} defaultValue={formatInputDate(new Date())} min={formatInputDate(new Date())} required />
                            </label>

                            <label>
                                <span>To</span>
                                <input type="date" onChange={(e) => setTo(e.target.value)} defaultValue={formatInputDate(new Date())} min={formatInputDate(new Date())} required />
                            </label>

                            <label>
                                <span>Room</span>
                                <input type="number" onChange={(e) => setRoom(e.target.value)} defaultValue={1} min={1} required />
                            </label>
                        </div>

                    </form>
                </div>

                <div className="info">
                    <div className="title">
                        <h3>Person</h3>
                    </div>

                    <form className="show-info">

                    <div className="row">
                            <label>
                                <span>Name</span>
                                <input type="text" onChange={(e) => setName(e.target.value)} placeholder="Name..." required />
                            </label>

                            <label>
                                <span>Surname</span>
                                <input type="text" onChange={(e) => setSurname(e.target.value)} placeholder="Surname..." required />
                            </label>
                        </div>

                        <div className="row">
                            <label>
                                <span>Birthday</span>
                                <input type="date" onChange={(e) => setBirthday(e.target.value)} required />
                            </label>

                            <label>
                                <span>Tel</span>
                                <input type="tel" onChange={(e) => setTel(e.target.value)} placeholder="+420 111 222 333" required />
                            </label>

                            <label>
                                <span>Email</span>
                                <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="example@gmail.com" required />
                            </label>
                        </div>

                    </form>
                </div>

                <div className="info">
                    <div className="title">
                        <h3>Address</h3>
                    </div>

                    <form className="show-info" onSubmit={createNewReservation}>

                        <div className="row">
                            <label>
                                <span>First Line</span>
                                <input type="text" onChange={(e) => setFirstLine(e.target.value)} placeholder="Long Street 820" required />
                            </label>

                            <label>
                                <span>Second Line</span>
                                <input type="text" onChange={(e) => setSecondLine(e.target.value)} placeholder="2 Floor" />
                            </label>

                            <label>
                                <span>City</span>
                                <input type="text" onChange={(e) => setCity(e.target.value)} placeholder="Prague" required />
                            </label>
                        </div>

                        <div className="row">
                            <label>
                                <span>Postal code</span>
                                <input type="text" onChange={(e) => setPsc(e.target.value)} placeholder="101 00" required />
                            </label>

                            <label>
                                <span>Country</span>
                                <input type="text" onChange={(e) => setCountry(e.target.value)} placeholder="Czech" required />
                            </label>
                        </div>

                        <div className="action">
                            <button className="btn blue">Create New</button>
                        </div>

                    </form>
                </div>

            </section>

        </Body>
    )
}

export default NewReservation;