import { Link } from "react-router-dom";
import "./Aside.css";

function Aside() {
    return(
        <aside>
            <Link to="/">
                <h1>Virtuoso</h1>
            </Link>

            <nav>
                <ul>
                    <li>
                        <Link to="/reservations">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24"><path d="m15.4 19.25c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75zm-1.4-14.25c0-.53-.47-1-1-1h-10c-.53 0-1 .47-1 1v14c0 .53.47 1 1 1h10c.53 0 1-.47 1-1zm-10.5.5h9v13h-9zm11.9 10.125c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75zm0-3.625c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75zm0-3.625c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75zm0-3.625c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75z" fillRule="nonzero"/></svg>
                            <span>Reservations</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/residents">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M12 2c2.757 0 5 2.243 5 5.001 0 2.756-2.243 5-5 5s-5-2.244-5-5c0-2.758 2.243-5.001 5-5.001zm0-2c-3.866 0-7 3.134-7 7.001 0 3.865 3.134 7 7 7s7-3.135 7-7c0-3.867-3.134-7.001-7-7.001zm6.369 13.353c-.497.498-1.057.931-1.658 1.302 2.872 1.874 4.378 5.083 4.972 7.346h-19.387c.572-2.29 2.058-5.503 4.973-7.358-.603-.374-1.162-.811-1.658-1.312-4.258 3.072-5.611 8.506-5.611 10.669h24c0-2.142-1.44-7.557-5.631-10.647z"/></svg>
                            <span>Residents</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/rooms">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  fillRule="evenodd" clipRule="evenodd"><path d="M24 19v-7c0-.265-.105-.52-.293-.707-.187-.188-.442-.293-.707-.293h-22c-.265 0-.52.105-.707.293-.188.187-.293.442-.293.707v7h1v-2h22v2h1zm-1-6.5c0-.133-.053-.26-.146-.354-.094-.093-.221-.146-.354-.146h-21c-.133 0-.26.053-.354.146-.093.094-.146.221-.146.354v3.5h22v-3.5zm-20-2.5v-3.5c0-.276.224-.5.5-.5h17c.276 0 .5.224.5.5v3.5h1v-4c0-.265-.105-.52-.293-.707-.187-.188-.442-.293-.707-.293h-18c-.265 0-.52.105-.707.293-.188.187-.293.442-.293.707v4h1zm2 0h6v-2c0-.552-.448-1-1-1h-4c-.552 0-1 .448-1 1v2zm8 0h6v-2c0-.552-.448-1-1-1h-4c-.552 0-1 .448-1 1v2zm-3-1v-.6c0-.221-.179-.4-.4-.4h-3.2c-.221 0-.4.179-.4.4v.6h4zm8 0v-.6c0-.221-.179-.4-.4-.4h-3.2c-.221 0-.4.179-.4.4v.6h4z"/></svg>
                            <span>Rooms</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/checkin">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24"><path d="m17.5 11c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5-4.5-2.016-4.5-4.5 2.016-4.5 4.5-4.5zm-5.346 6.999c-.052.001-.104.001-.156.001-4.078 0-7.742-3.093-9.854-6.483-.096-.159-.144-.338-.144-.517s.049-.358.145-.517c2.111-3.39 5.775-6.483 9.853-6.483 4.143 0 7.796 3.09 9.864 6.493.092.156.138.332.138.507 0 .179-.062.349-.15.516-.58-.634-1.297-1.14-2.103-1.472-1.863-2.476-4.626-4.544-7.749-4.544-3.465 0-6.533 2.632-8.404 5.5 1.815 2.781 4.754 5.34 8.089 5.493.09.529.25 1.034.471 1.506zm3.071-2.023 1.442 1.285c.095.085.215.127.333.127.136 0 .271-.055.37-.162l2.441-2.669c.088-.096.131-.217.131-.336 0-.274-.221-.499-.5-.499-.136 0-.271.055-.37.162l-2.108 2.304-1.073-.956c-.096-.085-.214-.127-.333-.127-.277 0-.5.224-.5.499 0 .137.056.273.167.372zm-3.603-.994c-2.031-.19-3.622-1.902-3.622-3.982 0-2.208 1.792-4 4-4 1.804 0 3.331 1.197 3.829 2.84-.493.146-.959.354-1.389.615-.248-1.118-1.247-1.955-2.44-1.955-1.38 0-2.5 1.12-2.5 2.5 0 1.363 1.092 2.472 2.448 2.499-.169.47-.281.967-.326 1.483z" fillRule="nonzero"/></svg>
                            <span>Check In</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/services">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fillRule="evenodd" clipRule="evenodd"><path d="M16 3h-8v-1h8v1zm4 10.228c-1.194.276-3.91.772-8 .772-4.091 0-6.807-.496-8-.772v-8.228h16v8.228zm.5-9.228h-17c-.276 0-.5.224-.5.5v9.5s3.098 1 9 1 9-1 9-1v-9.5c0-.276-.224-.5-.5-.5zm-5.5 14.5c0 .276-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5zm4 .5c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1zm0-3c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm-14 3c-.551 0-1-.448-1-1s.449-1 1-1c.551 0 1 .448 1 1s-.449 1-1 1zm0-3c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm18-5h-1v9c0 .621-.52 1-1 1h-18c-.617 0-1-.516-1-1v-9h-1v-3h1v-5c0-1.103.897-2 2-2h16c1.103 0 2 .897 2 2v5h1v3zm-3 12h-2v-1h2v1zm-14 0h-2v-1h2v1zm17-16v-4c0-1.657-1.343-3-3-3h-16c-1.657 0-3 1.343-3 3v4c-.552 0-1 .448-1 1v3c0 .552.448 1 1 1v8c0 1.239 1.037 2 2 2v1c0 .552.448 1 1 1h2c.552 0 1-.448 1-1v-1h10v1c0 .552.448 1 1 1h2c.552 0 1-.448 1-1v-1c.958 0 2-.758 2-2v-8c.552 0 1-.448 1-1v-3c0-.552-.448-1-1-1z"/></svg>
                            <span>Services</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/settings">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fillRule="evenodd" clipRule="evenodd"><path d="M12 8.666c-1.838 0-3.333 1.496-3.333 3.334s1.495 3.333 3.333 3.333 3.333-1.495 3.333-3.333-1.495-3.334-3.333-3.334m0 7.667c-2.39 0-4.333-1.943-4.333-4.333s1.943-4.334 4.333-4.334 4.333 1.944 4.333 4.334c0 2.39-1.943 4.333-4.333 4.333m-1.193 6.667h2.386c.379-1.104.668-2.451 2.107-3.05 1.496-.617 2.666.196 3.635.672l1.686-1.688c-.508-1.047-1.266-2.199-.669-3.641.567-1.369 1.739-1.663 3.048-2.099v-2.388c-1.235-.421-2.471-.708-3.047-2.098-.572-1.38.057-2.395.669-3.643l-1.687-1.686c-1.117.547-2.221 1.257-3.642.668-1.374-.571-1.656-1.734-2.1-3.047h-2.386c-.424 1.231-.704 2.468-2.099 3.046-.365.153-.718.226-1.077.226-.843 0-1.539-.392-2.566-.893l-1.687 1.686c.574 1.175 1.251 2.237.669 3.643-.571 1.375-1.734 1.654-3.047 2.098v2.388c1.226.418 2.468.705 3.047 2.098.581 1.403-.075 2.432-.669 3.643l1.687 1.687c1.45-.725 2.355-1.204 3.642-.669 1.378.572 1.655 1.738 2.1 3.047m3.094 1h-3.803c-.681-1.918-.785-2.713-1.773-3.123-1.005-.419-1.731.132-3.466.952l-2.689-2.689c.873-1.837 1.367-2.465.953-3.465-.412-.991-1.192-1.087-3.123-1.773v-3.804c1.906-.678 2.712-.782 3.123-1.773.411-.991-.071-1.613-.953-3.466l2.689-2.688c1.741.828 2.466 1.365 3.465.953.992-.412 1.082-1.185 1.775-3.124h3.802c.682 1.918.788 2.714 1.774 3.123 1.001.416 1.709-.119 3.467-.952l2.687 2.688c-.878 1.847-1.361 2.477-.952 3.465.411.992 1.192 1.087 3.123 1.774v3.805c-1.906.677-2.713.782-3.124 1.773-.403.975.044 1.561.954 3.464l-2.688 2.689c-1.728-.82-2.467-1.37-3.456-.955-.988.41-1.08 1.146-1.785 3.126"/></svg>
                            <span>Settings</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </aside>
    )
}

export default Aside;