import { useParams } from "react-router-dom";
import Body from "../../../comps/body/Body";
import { useEffect, useState } from "react";
import Loading from "../../../comps/loading/Loading";
import ApiManager from "../../../comps/Api";
import { formatDate, formatInputDate } from "../../../comps/StaticClasses";

function Room() {

    const { ID } = useParams();
    const[room, setRoom] = useState(null);
    const[isOpen, setOpen] = useState(false);
    const[date, setDate] = useState(false);
    const[reason, setReason] = useState(false);

    const apiManager = new ApiManager();

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const response = await apiManager.callApi("/getRoomBy/" + ID, "GET");

                setRoom(response);
            } catch (error) {
                console.error("Api ERROR: " + error.message);
            }
        };
        
        fetchDataFromApi();
    }, [ID]);

    const BlockRoom = async (event) => {
        event.preventDefault();

        const block = {
            "idRoom": ID,
            "blockedAt": date,
            "reason": reason
        }

        try {
            const response = await apiManager.callApi("/blockRoom", "POST", block);
            
            window.location.reload();
        } catch (error) {
            console.error("Api ERROR: " + error.message);
        }
    }

    if(room === null) {
        return (
            <Body title={"Room"}>
                <Loading />
            </Body>
        )
    }

    return(
        <Body title={"Room " + ID}>

            {isOpen && 
                <div className="modal-body">
                    <form onSubmit={BlockRoom}>
                        <div className="title-menu">
                            <h2>New Room</h2>
                            <button className="normal" onClick={() => setOpen(false)}>
                                <svg clipRule="evenodd" width="24" height="24" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>
                            </button>
                        </div>

                        <label>
                            <span>When</span>
                            <input type="date" onChange={(e) => setDate(e.target.value)} min={formatInputDate(new Date())} required />
                        </label>

                        <label>
                            <span>Reason</span>
                            <input type="text" onChange={(e) => setReason(e.target.value)} placeholder="Reason..." required />
                        </label>

                        <button className="btn" type="submit">Block</button>
                    </form>
                </div>
            }

            <section>

                <div className="info">
                    <div className="title">
                        <h3>Basic Information</h3>
                    </div>

                    <form className="show-info">

                        <div className="row">
                            <label>
                                <span>Id</span>
                                <input type="text" value={room.Id} placeholder="Id..." readOnly />
                            </label>

                            <label>
                                <span>Name</span>
                                <input type="text" value={room.Name} placeholder="Name..." readOnly required />
                            </label>

                            <label>
                                <span>Capacity</span>
                                <input type="number" value={room.Capacity} placeholder="Surname..." readOnly required />
                            </label>
                        </div>

                        <div className="action">
                            <button className="btn red">Delete</button>
                            <button type="button" className="btn" onClick={() => setOpen(true)}>Block</button>
                            <button className="btn blue">Save</button>
                        </div>

                    </form>
                </div>

                <div className="info">
                    <div className="title">
                        <h3>Blocking</h3>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Day</th>
                                <th>Reason</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>

                            {room.BlockedAt.map((block, index) => (
                                <tr key={index}>
                                    <td>{formatDate(block.BlockedAt)}</td>
                                    <td>{block.Reason}</td>
                                    <td>
                                        <button className="btn blue">Open</button>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                    
                </div>

            </section>

        </Body>
    )
}

export default Room;