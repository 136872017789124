import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Reservations from "./pages/reser/Reservations";
import Residents from "./pages/residents/Residents";
import Rooms from "./pages/rooms/Rooms";
import Settings from "./pages/settings/Settings";
import RouterProtector from "./comps/RouteProtector";
import Resident from "./pages/residents/action/Resident";
import Reserv from "./pages/reser/action/Reserv";
import Room from "./pages/rooms/action/Room";
import NewReservation from "./pages/reser/action/NewReserv";
import CheckIn from "./pages/checkIn/CheckIn";
import Services from "./pages/services/Services";

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/home",
      element: (
        <RouterProtector>
          <Home />
        </RouterProtector>
      ),
    },
    {
      path: "/reservations",
      element: (
        <RouterProtector>
          <Reservations />
        </RouterProtector>
      ),
    },
    {
      path: "/new-reservation",
      element: (
        <RouterProtector>
          <NewReservation />
        </RouterProtector>
      ),
    },
    {
      path: "/reservation/:ID",
      element: (
        <RouterProtector>
          <Reserv />
        </RouterProtector>
      ),
    },
    {
      path: "/check-in/:ID",
      element: (
        <RouterProtector>
          <CheckIn />
        </RouterProtector>
      ),
    },
    {
      path: "/residents",
      element: (
        <RouterProtector>
          <Residents />
        </RouterProtector>),
    },
    {
      path: "/resident/:ID",
      element: (
        <RouterProtector>
          <Resident />
        </RouterProtector>),
    },
    {
      path: "/rooms",
      element: (
        <RouterProtector>
          <Rooms />
        </RouterProtector>),
    },
    {
      path: "/room/:ID",
      element: (
        <RouterProtector>
          <Room />
        </RouterProtector>),
    },
    {
      path: "/services",
      element: ( 
        <RouterProtector>
          <Services />
        </RouterProtector>),
    },
    {
      path: "/settings",
      element: ( 
        <RouterProtector>
          <Settings />
        </RouterProtector>),
    },
    {
      path: "*",
      element: <Login />,
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
