export function formatDate(inputDateStr) {
    const inputDate = new Date(inputDateStr);
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1;
    const year = inputDate.getFullYear();
  
    return `${day}.${month}.${year}`;
}

export function formatInputDate(inputDateStr) {
    const inputDate = new Date(inputDateStr);
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1;
    const year = inputDate.getFullYear();

    if(month < 10 && day < 10) {
        return `${year}-0${month}-0${day}`;
    }

    if(day < 10) {
        return `${year}-${month}-0${day}`;
    }

    if(month < 10) {
        return `${year}-0${month}-${day}`;
    }
  
    return `${year}-${month}-${day}`;
}