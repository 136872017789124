import { useEffect, useState } from "react";
import Body from "../../comps/body/Body";
import ApiManager from "../../comps/Api";

function Settings() {

    const apiManager = new ApiManager();

    const[apiStatus, setStatus] = useState(null);

    useEffect(() => {

        const testApi = async () => {
            try {
                const response = await apiManager.callApi("/test", "GET");

                setStatus(response);
            } catch (error) {
                console.error("Api ERROR: " + error.message);
                setStatus(error.message);
            }
        };

        testApi();

    }, [])

    return(
        <Body title="Settings">

            <section>
                <div>
                    <h2>Is Api Online?</h2>
                    <h3 className={apiStatus === "Api is online!" ? "green" : "red"}>{apiStatus}</h3>
                </div>
                

            </section>

        </Body>
    )
}

export default Settings;