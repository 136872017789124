class ApiManager {
    baseUrl = 'http://localhost:5145';
  
    async callApi(endpoint, type, body) {

        const options = {
            method: type,
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        };

        try {
            console.log(`${this.baseUrl}${endpoint}`)

            const response = await fetch(`${this.baseUrl}${endpoint}`, options);

            if(response.ok) 
            {
                const data = await response.json();
                
                console.log(data);

                return data;

            } else 
            {
                console.log("Api call fail");
                return null;
            }

        } catch (error) {
            throw new Error(error);
        }
    }
}

export default ApiManager;