import { useEffect, useState } from "react";
import Body from "../../comps/body/Body";
import Loading from "../../comps/loading/Loading";
import ApiManager from "../../comps/Api";
import { Link } from "react-router-dom";
import "./Rooms.css";

function Rooms() {

    const[rooms, setRooms] = useState(null);

    const[isOpen, setOpen] = useState(false);
    const[name, setName] = useState(null); 
    const[capacity, setCapacity] = useState(null); 

    const apiManager = new ApiManager();

    useEffect(() => {

        const fetchDataFromApi = async () => {
            try {
                const response = await apiManager.callApi("/getRooms", "GET");
                
                setRooms(response);
            } catch (error) {
                console.error("Api ERROR: " + error.message);
            }
        };
        
        fetchDataFromApi();
    }, []);

    const CreateNewRoom = async (event) => {
        event.preventDefault();

        const newRoom = {
            "name": name,
            "capacity": capacity
        }

        try {
            const response = await apiManager.callApi("/createRoom", "POST", newRoom);
            
            window.location.reload();
        } catch (error) {
            console.error("Api ERROR: " + error.message);
        }
    }

    if(rooms === null) {
        return(
            <Body title="Rooms">
                <Loading />
            </Body>
        )
    }

    return(
        <Body title="Rooms">

            {isOpen && 
                <div className="modal-body">
                    <form onSubmit={CreateNewRoom}>
                        <div className="title-menu">
                            <h2>New Room</h2>
                            <button className="normal" onClick={() => setOpen(false)}>
                                <svg clipRule="evenodd" width="24" height="24" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>
                            </button>
                        </div>

                        <label>
                            <span>Name</span>
                            <input type="text" onChange={(e) => setName(e.target.value)} placeholder="Name..." required />
                        </label>

                        <label>
                            <span>Capacity</span>
                            <input type="number" onChange={(e) => setCapacity(e.target.value)} placeholder="6" min={1} required />
                        </label>

                        <button className="btn" type="submit">Add</button>
                    </form>
                </div>
            }

            <section>

                <div className="btn-body">
                    <button className="btn blue" onClick={() => setOpen(true)}>Create New</button>
                </div>
                
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Capacity</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody> 

                        {rooms.map((room, index) => (
                            <tr key={index}>
                                <td>{room.Id}</td>

                                <td>{room.Name}</td>

                                <td>{room.Capacity}</td>

                                <td>
                                    <Link className="btn" to={"/room/" + room.Id}>SHOW</Link>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>

            </section>

        </Body>
    )
}

export default Rooms;